import React, {useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from '../useStyles';
import Chart from "react-google-charts";

export default function Charts() {
    const classes = useStyles();
    const [dataReady, setDataReady] = React.useState(false);
    const [carData, setCarData] = React.useState([
        <MenuItem value={'0'}>{'Carregando...'}</MenuItem>
    ]);
    const [chartData, setChartData] = React.useState([]);
    const [vin, setVin] = React.useState('');
    const [type, setType] = React.useState('ENGINE_COOLANT_TEMPERATURE');

    const getCarData = async () => {
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/dash'
        )
        const json = await response.json();

        let table = json.map((item) => {
            return (
                <MenuItem value={item.vin}>{item.brand === "AUTO_BRAND" ? item.vin : item.brand + " " + item.model + " - " + item.name}</MenuItem>
            )
        });

        setCarData(table);
    };

    const getChartData = async (carVin, type) => {
        setDataReady(false);
        setVin(carVin);
        setType(type);
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/reading/' +
            carVin + '/' + type
        )
        const json = await response.json();

        const columns = [
            { type: 'date', label: 'Data/Hora' },
            { type: 'number', label: 'Valor' },
        ]

        let rows = []
        const nonNullData = json.filter(row => row.value !== null)
        for (let row of nonNullData) {
           const { datetime, value } = row
           rows.push([new Date(datetime), value])
        }

        setChartData([columns, ...rows]);
        setDataReady(true);
    };

    useEffect(() => {
        getCarData();
      }, []);

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="vin">Veículo</InputLabel>
                <Select
                    labelId="vin"
                    value={vin}
                    onChange={e => getChartData(e.target.value, type)}
                >
                    {carData}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="type">Leitura</InputLabel>
                <Select
                    labelId="type"
                    value={type}
                    onChange={e => getChartData(vin, e.target.value)}
                >
                    <MenuItem value={'ENGINE_COOLANT_TEMPERATURE'}>Temperatura do Motor</MenuItem>
                    <MenuItem value={'DISTANCE_SINCE_CODES_CLEARED'}>Odômetro</MenuItem>
                    <MenuItem value={'FUEL_TANK_LEVEL_INPUT'}>Nível do Tanque</MenuItem>
                    <MenuItem value={'RUNTIME_SINCE_ENGINE_START'}>Duração da viagem</MenuItem>
                    <MenuItem value={'VEHICLE_SPEED'}>Velocidade</MenuItem>
                </Select>
            </FormControl>

            {
            dataReady ?
            <Chart
                    width={'600px'}
                    height={'400px'}
                    chartType="LineChart"
                    loader={<div>Carregando gráfico...</div>}
                    data={chartData}
                    rootProps={{ 'data-testid': '1' }}
                />
                : ""
            }
        </div>
    );

}
