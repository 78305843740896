import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class Notification extends React.Component {
    constructor() {
        super();

        this.state = {
            last: []
        }
    }

    queryDashboard() {
        fetch(`http://carroemdados.com.br:1880/api/alarms`)
            .then(results => {
                return results.json();
            }).then(data => {
                let dash = data.map((car) => {
                    let ts = new Date(car.datetime);
                    return (
                        <TableRow key={car.vin}>
                            <TableCell>{car.brand === "AUTO_BRAND" ? car.vin : car.brand + " " + car.model + " - " + car.name}</TableCell>
                            <TableCell>{ts.getFullYear() + "/" + ('0' + (ts.getMonth() + 1)).slice(-2) + "/" + ('0' + ts.getDate()).slice(-2) + " " + ('0' + ts.getHours()).slice(-2) + ":" + ('0' + ts.getMinutes()).slice(-2) + ":" + ('0' + ts.getSeconds()).slice(-2)}</TableCell>
                            <TableCell>{car.alarmname}</TableCell>
                            <TableCell align="right">{car.currentkm}</TableCell>
                            <TableCell align="right">{car.limitkm}</TableCell>
                        </TableRow>
                    )
                })
                this.setState({ last: dash });
            })
    }

    componentDidMount() {
        this.queryDashboard();
    }

    render() {
        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ve&iacute;culo</TableCell>
                            <TableCell>Data/Hora</TableCell>
                            <TableCell>Notificação</TableCell>
                            <TableCell align="right">Km Atual</TableCell>
                            <TableCell align="right">Km Limite</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.last}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default Notification;
