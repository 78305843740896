import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from '../useStyles';
import Dashboard from './Dashboard';
import Messages from './Messages';
import Trip from './Trip';
import Charts from './Charts';
import Notification from './Notification';
import Configuration from './Configuration';

export default function MainMenu() {
    const classes = useStyles();
    const [page, setPage] = React.useState('dash');
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleDashButton = () => {
        setPage('dash');
    };

    const handleMsgButton = () => {
        setPage('msg');
    };

    const handleTripButton = () => {
        setPage('trip');
    };

    const handleChartsButton = () => {
        setPage('charts');
    };

    const handleNotifButton = () => {
        setPage('notif');
    };

    const handleConfigButton = () => {
        setPage('config');
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Carro em Dados
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button key='Dashboard' onClick={handleDashButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItem>
                    <ListItem button key='Mensagens' onClick={handleMsgButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Mensagens' />
                    </ListItem>
                    <ListItem button key='Viagens' onClick={handleTripButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Viagens' />
                    </ListItem>
                    <ListItem button key='Graficos' onClick={handleChartsButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Gráficos' />
                    </ListItem>
                    <ListItem button key='Notif' onClick={handleNotifButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Notificações' />
                    </ListItem>
                    <ListItem button key='Config' onClick={handleConfigButton}>
                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                        <ListItemText primary='Configuração' />
                    </ListItem>
                </List>
            </Drawer>
            <main className={clsx(classes.content, { [classes.contentShift]: open, })}>
                <div className={classes.drawerHeader} />
                {page === 'dash' ? <Dashboard /> : ''}
                {page === 'msg' ? <Messages /> : ''}
                {page === 'trip' ? <Trip /> : ''}
                {page === 'charts' ? <Charts /> : ''}
                {page === 'notif' ? <Notification /> : ''}
                {page === 'config' ? <Configuration /> : ''}
            </main>
        </div>
    );

}
