import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class Dashboard extends React.Component {
    constructor() {
        super();

        this.state = {
            last: []
        }
    }

    queryDashboard() {
        fetch(`http://carroemdados.com.br:1880/api/dash`)
            .then(results => {
                return results.json();
            }).then(data => {
                let dash = data.map((car) => {
                    let ts = new Date(car.lastreadingtime);
                    let tss = new Date(car.lastreceivedtime);
                    return (
                        <TableRow key={car.vin}>
                            <TableCell>{car.brand === "AUTO_BRAND" ? car.vin : car.brand + " " + car.model + " - " + car.name}</TableCell>
                            <TableCell>{ts.getFullYear() + "/" + ('0' + (ts.getMonth() + 1)).slice(-2) + "/" + ('0' + ts.getDate()).slice(-2) + " " + ('0' + ts.getHours()).slice(-2) + ":" + ('0' + ts.getMinutes()).slice(-2) + ":" + ('0' + ts.getSeconds()).slice(-2)}</TableCell>
                            <TableCell>{tss.getFullYear() + "/" + ('0' + (tss.getMonth() + 1)).slice(-2) + "/" + ('0' + tss.getDate()).slice(-2) + " " + ('0' + tss.getHours()).slice(-2) + ":" + ('0' + tss.getMinutes()).slice(-2) + ":" + ('0' + tss.getSeconds()).slice(-2)}</TableCell>
                            <TableCell align="right">{car.lastkm}</TableCell>
                            <TableCell align="right">{car.gpslastkm}</TableCell>
                        </TableRow>
                    )
                })
                this.setState({ last: dash });
            })
    }

    componentDidMount() {
        this.queryDashboard();
    }

    render() {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ve&iacute;culo</TableCell>
                            <TableCell>Data/Hora Leitura</TableCell>
                            <TableCell>Data/Hora Envio</TableCell>
                            <TableCell align="right">Km Atual (OBD2)</TableCell>
                            <TableCell align="right">Km Atual (GPS)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.last}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default Dashboard;
