import React, {useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from '../useStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function Messages() {
    const classes = useStyles();
    const [carData, setCarData] = React.useState([
        <MenuItem value={'0'}>{'Carregando...'}</MenuItem>
    ]);
    const [tableData, setTableData] = React.useState([]);
    const [vin, setVin] = React.useState('');
    const [type, setType] = React.useState('ENGINE_COOLANT_TEMPERATURE');

    const getCarData = async () => {
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/dash'
        )
        const json = await response.json();

        let table = json.map((item) => {
            return (
                <MenuItem value={item.vin}>{item.brand === "AUTO_BRAND" ? item.vin : item.brand + " " + item.model + " - " + item.name}</MenuItem>
            )
        });

        setCarData(table);
    };

    const getTableData = async (carVin, type) => {
        setTableData([]);
        setVin(carVin);
        setType(type);
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/reading/' +
            carVin + '/' + type
        )
        const json = await response.json();

        let table = json.map((item) => {
            let ts = new Date(item.datetime);
            return (
                <TableRow key={ts}>
                    <TableCell>{ts.getFullYear() + "/" + ('0' + (ts.getMonth() + 1)).slice(-2) + "/" + ('0' + ts.getDate()).slice(-2) + " " + ('0' + ts.getHours()).slice(-2) + ":" + ('0' + ts.getMinutes()).slice(-2) + ":" + ('0' + ts.getSeconds()).slice(-2)}</TableCell>
                    <TableCell>{item.value}</TableCell>
                </TableRow>
            )
        });

        setTableData(table);
    };

    useEffect(() => {
        getCarData();
      }, []);

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="vin">Veículo</InputLabel>
                <Select
                    labelId="vin"
                    value={vin}
                    onChange={e => getTableData(e.target.value, type)}
                >
                    {carData}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="type">Leitura</InputLabel>
                <Select
                    labelId="type"
                    value={type}
                    onChange={e => getTableData(vin, e.target.value)}
                >
                    <MenuItem value={'ENGINE_COOLANT_TEMPERATURE'}>Temperatura do Motor</MenuItem>
                    <MenuItem value={'DISTANCE_SINCE_CODES_CLEARED'}>Odômetro (OBD2)</MenuItem>
                    <MenuItem value={'GPS_CURRENT_KM'}>Odômetro (GPS)</MenuItem>
                    <MenuItem value={'FUEL_TANK_LEVEL_INPUT'}>Nível do Tanque</MenuItem>
                    <MenuItem value={'RUNTIME_SINCE_ENGINE_START'}>Duração da viagem</MenuItem>
                    <MenuItem value={'VEHICLE_SPEED'}>Velocidade</MenuItem>
                    <MenuItem value={'DTC'}>Falhas</MenuItem>
                </Select>
            </FormControl>

            {
            tableData.length > 0 ?

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data/Hora</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData}
                    </TableBody>
                </Table>
            </TableContainer>

            : ""}
            
        </div>
    );

}
