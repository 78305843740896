import React, {useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useStyles from '../useStyles';

export default function Configuration() {
    const classes = useStyles();
    const [carData, setCarData] = React.useState([
        <MenuItem value={'0'}>{'Carregando...'}</MenuItem>
    ]);
    const [tableData, setTableData] = React.useState([]);
    const [vin, setVin] = React.useState('');
    const [limitKm, setLimitKm] = React.useState([]);
    const [lastKm, setLastKm] = React.useState('');

    const getCarData = async () => {
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/dash'
        )
        const json = await response.json();

        let table = json.map((item) => {
            return (
                <MenuItem value={item.vin}>{item.brand === "AUTO_BRAND" ? item.vin : item.brand + " " + item.model + " - " + item.name}</MenuItem>
            )
        });

        setCarData(table);
    };

    const configureLimit = async () => {
        for (let item of limitKm){
            if(item && item.alarmid != null){
                let body = {
                    "car": vin,
                    "limitkm": item.limit,
                    "alarmid": item.alarmid,
                };

                console.log(body);

                await fetch(
                    'http://www.carroemdados.com.br:1880/api/notif/', {
                        method: 'PUT',
                        body: JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
            }
        }

        setTableData([]);
    }

    const handleLimitChange = (index, alarmid) => event => {
        const newLimits = limitKm;
        newLimits[index] = { "alarmid": alarmid, "limit": event.target.value };
        setLimitKm(newLimits);
    }

    const getTableData = async (carVin) => {
        setTableData([]);
        setVin(carVin);
        const response = await fetch(
            'http://www.carroemdados.com.br:1880/api/notif/' +
            carVin
        )
        const json = await response.json();

        setLastKm(json[0].lastkm);

        let table = json.map((item, index) => {
            return (
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField id={"kmatual" + index} label="Notificação" defaultValue={item.alarmname} InputProps={{ readOnly: true, }} variant="outlined"/>
                    <TextField id={"kmalarm" + index} label="KM Limite" defaultValue={item.limitkm} variant="outlined"
                        onChange={handleLimitChange(index, item.alarmid)} />
                </form>
            )
        });

        setTableData(table);
    };

    useEffect(() => {
        getCarData();
      }, []);

    return (
        <div>

            <FormControl className={classes.formControl}>
                <InputLabel id="vin">Veículo</InputLabel>
                <Select
                    labelId="vin"
                    value={vin}
                    onChange={e => getTableData(e.target.value)}
                >
                    {carData}
                </Select>
            </FormControl>

            {
                tableData.length > 0 ? <p>KM Atual: <b>{lastKm}</b></p>
                : ""
            }

            {tableData}

            {
                tableData.length > 0 ? <Button variant="contained" color="primary" onClick={() => configureLimit()}>Salvar</Button>
                : ""
            }

        </div>
    );

}
